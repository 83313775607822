html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
}

#root {
  margin-left: 1em;
  height: 100%;
  margin-right: 1em;
}

#bgoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
  animation: fadeIn ease 0.8s;
  -webkit-transition: background-image 0.5s ease-in-out;
  transition: background-image 0.5s ease-in-out;
  filter: blur(5px);
}

@media (min-height: 1081px) {
  .bgLight {
    background-image: url("/bg/bg1.jpg");
  }

  .bgDark {
    background-image: url("/bg/bg2.jpg");
  }
}

@media (max-height: 1080px) {
  .bgLight {
    background-image: url("/bg/bg1_1080.jpg");
  }

  .bgDark {
    background-image: url("/bg/bg2_1080.jpg");
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
